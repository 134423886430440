import React , { useState }from "react";
import Header from "src/component/title/headers";
import Footer from "src/component/title/footer";
import { Image } from 'antd';
import "../style.css";

export default function YETMİSBEST() {
    const [visible, setVisible] = useState(false);
  return (
    <><>
          <Header />
          <div className="product">
              <div className="productHeader">
                  <h2>Jabra Elite 75T</h2>
              </div>
              <div>
                  <div className="productContent">
                      <div className="productList" style={{ width: '50%' }}>
                          <ul>
                              Özellikler
                              <li>
                                  ORJINAL VE SIFIR - Çalışır testleri sadece yapılmış olup
                                  kullanılmamıştır.
                              </li>
                              <li>
                                  Ürün alım aşamasında kutu açılımını yapar, Sound+ uygulamasına
                                  bağlar ve gerekli ses kalite testini sağlayabilirsiniz.
                              </li>
                              <li>
                                  Anlamsız teklifler lütfen etmeyiniz. Jabra'nın en üst seviye
                                  bluetooth modellerinden birisidir. Çok temiz bass ve tiz ses
                                  iletişimi vardır.
                              </li>
                              <li>Kutu vs. Aksesuarı tamdır.</li>
                              <li>
                                  Istanbul And. Elden teslim. Konum : Ataşehir-İçerenköy veya
                                  Beykoz-Kavacık. Diğer illere önden ödemeli kargo gönderim de
                                  yapabilirim. Yada ek komisyon farkını da ödeyerek güvenli
                                  kargo ile de satın alabilirsiniz.
                              </li>
                          </ul>
                          <p>
                              Lorem ipsum dolor sit amet consectetur, adipisicing elit.
                              Corporis, sapiente eveniet corrupti reiciendis adipisci dolorum
                              hic itaque, asperiores non ab distinctio. Dolore expedita
                              magnam, eligendi eos error quidem repudiandae illum?
                          </p>
                      </div>
                      <div className="productImages">
                          <Image
                              preview={{ visible: false }}
                              width={200}
                              src="https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp"
                              onClick={() => setVisible(true)} />
                          <div style={{ display: "none" }}>
                              <Image.PreviewGroup
                                  preview={{
                                      visible,
                                      onVisibleChange: (vis) => setVisible(vis),
                                  }}
                              >
                                  <Image src="https://gw.alipayobjects.com/zos/antfincdn/LlvErxo8H9/photo-1503185912284-5271ff81b9a8.webp" />
                                  <Image src="https://gw.alipayobjects.com/zos/antfincdn/cV16ZqzMjW/photo-1473091540282-9b846e7965e3.webp" />
                                  <Image src="https://gw.alipayobjects.com/zos/antfincdn/x43I27A55%26/photo-1438109491414-7198515b166b.webp" />
                              </Image.PreviewGroup>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </><Footer /></>
  );
}
