import React from "react";
import '../../index.css';

export default function Footer() {
    return (
      <div className="footer">
            <div className="footerLogo">
              <div>
                <img style={{maxWidth: '150px'}}  src={require('../../images/logo.png')} alt="sefaesenel-logo" />
                </div>
            </div>
            <div>
              <h5>Ürünlerimiz</h5>
              <ul>
                <li><a href="">1</a></li>
                <li><a href="">2</a></li>
                <li><a href="">3</a></li>
              </ul>
            </div>
      </div>
    );
}