import React from "react";
import '../../index.css';

export default function Header() {
    return (
      <div className="header">
            <div className="headerLogo">
                <img style={{width: '10%'}}  src={require('../../images/logo.png')} alt="sefaesenel-logo" />
            </div>
      </div>
    );
}