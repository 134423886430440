import React from "react";
import Header from "src/component/title/headers";
import Footer from "src/component/title/footer";
import '../../index.css';
import { Button, Card, List } from "antd";

const data = [
    {
        title: 'Title 1',
        content: 'asdfasdf',
        images: require('../../images/logo.png'),
        href: '/elite-75t',
    },
    {
        title: 'Title 2',
    },
    {
        title: 'Title 3',
    },
    {
        title: 'Title 4',
    },
    {
        title: 'Title 5',
    },
    {
        title: 'Title 6',
    },
];

export default function Home() {
    return (
        <><><Header />
            <div className="" style={{ display: 'flex' }}>
                <div className="bizKimiz">
                    <h1>Biz <br /> Kimiz</h1>
                    <span></span>
                </div>
                <div className="bizKimizAcıklama">
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Itaque repellat eos ullam molestias delectus adipisci neque amet! Maxime minus rerum unde facilis magni eum, vitae et ullam? Fuga, sequi labore.</p>
                </div>
            </div>
            <div className="cardArea">
                <h2>ÜRÜNLERİMİZ</h2>
                <List style={{ margin: '50px' }}
                    grid={{
                        gutter: 16,
                        xs: 1,
                        sm: 2,
                        md: 4,
                        lg: 4,
                        xl: 4,
                        xxl: 3,
                    }}
                    dataSource={data}
                    renderItem={(item) => (
                        <List.Item>
                            <Card
                                cover={<img
                                    alt="example"
                                    src={item.images} />}
                                title={item.title}
                            >
                                {item.content}
                                <Button><a href={item.href}>İncelemek İçin Tıklayınız</a></Button>
                            </Card>
                        </List.Item>
                    )} />
            </div>
            </><Footer /></>
    );
}
